const initialState = {
  counter: 0,
  network: 'mumbai', //'mumbai', //"polygon", //"baseTest"
  contractAddress: '0x332aDC28479CC05fF521C30Ec814853aF331Bcbe',
  tab: 'home',
  loading: true,
  user: {},
  url: 'https://standup.chat',
}

let root = (state = initialState, action) => {
  switch (action.type) {
    case 'increment':
      return {...state, counter: state.counter + 1}
    case 'decrement':
      return {...state, counter: state.counter - 1}
    case 'set':
      return {...state, ...action.payload}
    case 'toggleSidebar':
      let {showSideBar} = state
      return {...state, showSideBar: !showSideBar}
    default:
      return state
  }
}

export default root
