import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Tiles from './tiles/Tiles'
import Box from './box/Box'

let Menu = ({}) => {
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)

  //<Tiles />

  return (
    <div style={styles.page}>
      {tab == 'wallet' && <div>wallets</div>}
      {tab == 'home' && <Box />}
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
  },
}

export default Menu
