import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Axios from 'axios'
import {w3cwebsocket as W3CWebSocket} from 'websocket'

import Arc from './Arc'

function os(object) {
  return JSON.stringify(object)
}

let Tiles = ({}) => {
  let [timer, setTimer] = useState(0)
  let [from, setFrom] = useState(null)
  let [to, setTo] = useState(null)

  let [wid, setWid] = useState('')
  let [room, setRoom] = useState(null)
  let [on, setOn] = useState(false)
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let [board, setBoard] = useState(Array.from({length: 594}, () => ({})))
  let [arcs, setArcs] = useState([])
  let [nextId, setNextId] = useState(1)
  let ws = useRef(null)

  useEffect(() => {
    ws.current = new W3CWebSocket('wss://standup.chat:7072') //('ws://localhost:7072') //
    initWs()
  }, [])

  let toggleBoard = k => {
    if (k == from) {
      setFrom(null)
      return
    }
    if (k == to) {
      setTo(null)
      return
    }
    if (!from) {
      setFrom(k)
      return
    }
    if (!to) {
      setTo(k)
      return
    }
  }
  useEffect(() => {
    if (from && to) {
      shoot()
      setFrom(null)
      setTo(null)
    }
  }, [timer])

  let make = () => {
    ws.current.send(os({action: 'make', wid, name: 'jose', board}))
    setRoom(wid)
  }

  let start = () => {
    ws.current.send(os({action: 'start', roomId: room, board}))
    setOn(true)
  }

  let initWs = () => {
    ws.current.onmessage = message => {
      let obj = JSON.parse(message.data)

      if (obj.message == 'welcome') {
        console.log('wid', obj.id)
        setWid(obj.id)
      }

      if (obj.message == 'update') {
        console.log('update', obj)
        setTimer(key => key + 1)
      }
    }
  }

  let show = board?.map((a, k) => {
    let backgroundColor = '#fbf7f5'
    let borderColor = '#aaa'
    let opacity = 1
    if (from == k) {
      backgroundColor = '#d4dfcb' //green
      borderColor = '#adc29c'
    }

    if (to == k) {
      backgroundColor = '#c9cdf5' //blue
      borderColor = '#9ea5ed'
    }

    return (
      <div onClick={() => toggleBoard(k)} key={k} style={{...styles.box, overflow: 'hidden'}}>
        <div style={{...styles.tile, backgroundColor, opacity, borderColor}}>
          {k == 18 && (
            <div className="circles">
              <div className="circle1" />
              <div className="circle2" />
            </div>
          )}
        </div>
      </div>
    )
  })

  const removeArc = id => {
    setArcs(arcs => arcs.filter(a => a.id !== id))
  }

  const addArc = (x, y, x2, y2) => {
    setArcs(arcs => [...arcs, {id: nextId, x, y, x2, y2}])
    setNextId(nextId + 1) // Increment the nextId
  }

  let showArcs = arcs?.map(a => {
    return <Arc key={a.id} x={a.x} y={a.y} x2={a.x2} y2={a.y2} onComplete={() => removeArc(a.id)} />
  })

  let shoot = () => {
    let fx = from % 33
    let fy = 18 - Math.floor((from / 594) * 18)
    let tx = to % 33
    let ty = 18 - Math.floor((to / 594) * 18)

    if (from && to) {
      let x = fx * 48 + 24
      let y = fy * -48 + 24
      let x2 = tx * 48 + 24
      let y2 = ty * -48 + 24
      addArc(x, y, x2, y2)
      //
    }
  }

  return (
    <div className={'flexCol'} style={{position: 'relative'}}>
      <div
        className={'flexRow'}
        style={{
          flexWrap: 'wrap',
          alignSelf: 'stretch',
          justifyContent: 'center',
          marginTop: 100,
          width: 1584,
        }}>
        {show}
      </div>
      <div style={{backgroundColor: 'blue', width: 1584, overflow: 'visible'}}>{showArcs}</div>
      {/* timer */}
      {on && (
        <>
          <div className="timer" style={{width: 200, height: 8, alignSelf: 'flex-end', marginTop: 4, marginRight: 2}}>
            <div key={timer} className="bar"></div>
          </div>
        </>
      )}
      {/* buttons */}
      {!on && (
        <>
          <div
            onClick={() => make()}
            style={{
              height: 47,
              width: 47,
              backgroundColor: room ? '#eee' : '#888',
              position: 'absolute',
              bottom: -97,
              right: 2,
            }}></div>
          <div
            onClick={() => start()}
            style={{height: 47, width: 47, backgroundColor: '#555', position: 'absolute', bottom: -50, right: 2}}></div>
        </>
      )}
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
    marginBottom: 80,
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
  },
  tile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 42,
    width: 42,
    borderRadius: 6,
    border: '1px solid #aaa',
  },
}

export default Tiles
