import React, {useRef, useEffect, useState} from 'react'
import {gsap, MotionPathPlugin} from 'gsap/all'

gsap.registerPlugin(MotionPathPlugin)

const Arc = ({x, y, x2, y2, onComplete}) => {
  const projectileRef = useRef(null)
  const [pathPoints, setPathPoints] = useState([{x, y}])

  //peak
  let peakHeight = y2 - 300
  if (y < y2) peakHeight = y - 300
  //mid
  let midX = (x2 - x) / 2 + x
  let midY = peakHeight
  //no center
  if (x == 792 && x2 == 792) midX = 802

  const fullPath = `M ${x} ${y} Q ${midX} ${midY}, ${x2} ${y2}`

  useEffect(() => {
    if (projectileRef.current) {
      const path = [
        {x: x, y: y},
        {x: midX, y: midY},
        {x: x2, y: y2},
      ]

      const tl = gsap.timeline({
        defaults: {ease: 'power1.inOut'}, //power1.inOut //none
        onUpdate: () => updatePath(tl.progress(), path),
        onComplete: () => onAnimationComplete(),
      })

      tl.to(projectileRef.current, {
        motionPath: {
          path: path,
          curviness: 1, //1.5
          autoRotate: true,
        },
        duration: 5,
      })
    }
  }, [])

  const onAnimationComplete = () => {
    if (onComplete) {
      onComplete()
    }
  }

  const interpolate = (pointA, pointB, progress) => {
    return {
      x: pointA.x + (pointB.x - pointA.x) * progress,
      y: pointA.y + (pointB.y - pointA.y) * progress,
    }
  }

  const calculateBezierPoint = (t, p0, p1, p2) => {
    const x = (1 - t) ** 2 * p0.x + 2 * (1 - t) * t * p1.x + t ** 2 * p2.x
    const y = (1 - t) ** 2 * p0.y + 2 * (1 - t) * t * p1.y + t ** 2 * p2.y
    return {x, y}
  }

  const updatePath = (progress, path) => {
    const currentPoint = calculateBezierPoint(progress, path[0], path[1], path[2])
    //setPathPoints(prevPoints => [...prevPoints, currentPoint])
    setPathPoints(prevPoints => {
      const newPoints = [...prevPoints, currentPoint]
      return newPoints.slice(-15)
    })
  }

  const getPathDAttribute = () => {
    return pathPoints
      .map((point, index) => {
        return `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`
      })
      .join(' ')
  }

  return (
    <div className={'arc'} style={{position: 'absolute', pointerEvents: 'none'}}>
      <svg style={{overflow: 'visible'}}>
        <path d={fullPath} fill="none" stroke="#ccc" strokeWidth="2" />
        <path d={getPathDAttribute()} fill="none" stroke="#000" strokeWidth="2" />
      </svg>
      <div ref={projectileRef} />
    </div>
  )
}

export default Arc
