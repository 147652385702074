import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Top = ({toggle, aim, board}) => {
  let [show, setShow] = useState(0)

  let spot = num => {
    let obj = board[num - 1] || {}
    return (
      <div
        onClick={() => toggle(num)}
        className={'rotate45'}
        style={{height: 60, width: 60, borderRadius: 4, border: '1px solid #888'}}>
        <div style={{height: 60, width: 60, borderRadius: 4, backgroundColor: obj ? obj?.backgroundColor : '#fbf7f5'}} />
        {aim == num && <div style={styles.aim}></div>}
      </div>
    )
  }

  return (
    <div className={'flexCenter'} style={{flex: 1}}>
      <div
        className={'flexCol'}
        style={{
          width: 330,
          height: 330,
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 20,
        }}>
        {spot(3)}
        <div className={'flexRow'} style={{justifyContent: 'space-between', alignSelf: 'stretch'}}>
          {spot(2)}
          {spot(4)}
        </div>
        {spot(1)}
      </div>
    </div>
  )
}

export default Top

let styles = {
  aim: {
    height: 73,
    width: 73,
    borderRadius: 3,
    border: '1px solid #ff6282',
    position: 'absolute',
    top: 0,
    marginTop: -7.5,
    marginLeft: -7.5,
    zIndex: 19,
  },
}
