import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Menu = ({wid, on, clock, room, join, make, lobby, start, joinId, setJoinId}) => {
  let [fade, setFade] = useState(0)

  //fadeIn
  useEffect(() => {
    if (wid) {
      const timeoutId = setTimeout(() => setFade(1), 10)
      return () => clearTimeout(timeoutId)
    }
  }, [wid])

  let showLobby = lobby?.map(a => {
    return (
      <div
        key={a.wid}
        className={'flexCenter'}
        style={{
          height: 46,
          width: 46,
          border: '1px solid #888',
          borderRightWidth: 0,
          borderBottomWidth: 0,
          overflow: 'hidden',
        }}>
        {`${a.name}`}
      </div>
    )
  })

  return (
    <div
      style={{
        transition: 'opacity 600ms ease-in-out',
        opacity: fade,
      }}>
      {/* timer */}
      {on && (
        <div
          className="timer"
          style={{
            width: 360,
            height: 8,
            alignSelf: 'flex-end',
            marginTop: 4,
            marginRight: 2,
            position: 'absolute',
            bottom: 0,
          }}>
          <div key={clock} className="bar" />
        </div>
      )}
      {/* buttons */}
      {!on && wid && clock == 0 && (
        <>
          {!room && (
            <>
              <div className={'flexRow'} style={{position: 'absolute', bottom: 0, left: 0}}>
                <div
                  className={'flexRow'}
                  onClick={() => join()}
                  style={{
                    height: 47,
                    width: 47,
                    backgroundColor: '#aaa',

                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 30,
                  }}>
                  {`-`}
                </div>
                <input
                  className={'lato300'}
                  type="number"
                  inputMode="numeric"
                  style={styles.inputRowRight}
                  value={joinId}
                  onChange={e => {
                    setJoinId(e.target.value)
                  }}
                  placeholder={'000000'}
                />
              </div>

              <div
                className={'flexRow'}
                onClick={() => make()}
                style={{
                  height: 47,
                  width: 47,
                  backgroundColor: '#aaa',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 30,
                }}>
                {`+`}
              </div>
            </>
          )}
          {room && (
            <div className={'flexRow'} style={{position: 'absolute', bottom: 0, right: 0, alignItems: 'center'}}>
              <div style={{marginRight: 10, fontSize: 22, opacity: 0.7}}>{`#${room}`}</div>
              <div onClick={() => start()} style={{height: 47, width: 47, backgroundColor: '#555'}} />
            </div>
          )}
          {lobby && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                position: 'absolute',
                bottom: 47,
                right: 0,
              }}>
              {showLobby}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Menu

let styles = {
  inputRowRight: {
    backgroundColor: '#fbf7f5',
    border: '1px solid #888',
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 94,
    height: 47,
    paddingLeft: 10,
    fontSize: 20,
    flex: 1,
  },
}
