import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Axios from 'axios'
import {w3cwebsocket as W3CWebSocket} from 'websocket'

import Card from './Card'
import Top from './Top'
import Menu from './Menu'

import {getCard} from '../../util/helpers'

//import Arc from '../tiles/Arc'

function os(object) {
  return JSON.stringify(object)
}

let Tiles = ({}) => {
  let [clock, setClock] = useState(0)
  let [from, setFrom] = useState(null)
  let [to, setTo] = useState(null)
  let [lobby, setLobby] = useState([])
  let lobbyRef = useRef([])
  let [joinId, setJoinId] = useState('')

  let [wid, setWid] = useState('')
  let [room, setRoom] = useState(null)
  let [on, setOn] = useState(false)
  let [arcs, setArcs] = useState([])
  let [nextId, setNextId] = useState(1)
  let ws = useRef(null)

  //
  let [go, setGo] = useState(false)
  let goRef = useRef(go)
  //
  let [aim, setAim] = useState(0)
  let [board, setBoard] = useState([{}, {}, {}, {}])

  useEffect(() => {
    goRef.current = go
  }, [go])

  useEffect(() => {
    ws.current = new W3CWebSocket('wss://standup.chat:7070') //('ws://localhost:7072') //
    initWs()
  }, [])

  let make = () => {
    ws.current.send(os({action: 'make', wid, name: 'jose', stats: {}}))
  }

  let start = () => {
    ws.current.send(os({action: 'start', roomId: room, stats: {}}))
  }

  let join = () => {
    console.log('join', joinId)
    if (joinId?.length != 6) return
    ws.current.send(os({action: 'join', roomId: joinId, wid, name: 'jj', stats: {}}))
  }

  let initWs = () => {
    ws.current.onmessage = message => {
      let obj = JSON.parse(message.data)

      if (obj.message == 'welcome') {
        setWid(obj.id)
      }

      if (obj.message == 'lobby') {
        console.log('lobby', obj.roomId, obj.players)
        setRoom(obj.roomId)
        setLobby(obj.players)
        lobbyRef.current = obj.players
      }

      if (obj.message == 'on') {
        setOn(true)
      }

      if (obj.message == 'update') {
        setClock(key => key + 1)
        setGo(true)
        act(obj?.moves)
      }
    }
  }

  let place = (pNum, card) => {
    setBoard(prev => {
      prev[pNum - 1] = card
      return prev
    })
  }

  console.log('board', board)

  let act = moves => {
    moves?.forEach(a => {
      console.log('a', a)
      let card = getCard(a.id)
      console.log('card', card)

      let pNum = 0
      lobbyRef.current?.forEach((b, p) => {
        if (b.wid == a.wid) pNum = p + 1
      })

      //cast
      if (a.dir == 'right') {
        //creature
        if (card?.type == 'creature') {
          place(pNum, card)
        }
      }
    })
  }

  let toggle = num => {
    if (!go) return
    if (aim == num) {
      setAim(0)
      return
    }
    setAim(num)
  }

  let [deck, setDeck] = useState([
    {id: 1, backgroundColor: 'yellow'},
    {id: 2, backgroundColor: 'blue'},
    {id: 3, backgroundColor: 'green'},
    {id: 4, backgroundColor: 'silver'},
    {id: 5, backgroundColor: 'purple'},
    {id: 6, backgroundColor: 'grey'},
    {id: 7, backgroundColor: 'pink'},
    {id: 8, backgroundColor: 'orange'},
    {id: 9, backgroundColor: 'red'},
  ])
  let [yard, setYard] = useState([])

  const onSwipe = (dir, id) => {
    if (!goRef.current) return

    console.log('-', dir, id, 'aim - ', aim)
    ws.current.send(os({action: 'move', roomId: room, move: {wid, dir, id, aim}}))

    setGo(false)
    setDeck(prevDeck => {
      const card = prevDeck.find(a => a.id === id)
      const newDeck = prevDeck.filter(a => a.id !== card.id)
      return newDeck
    })
    setYard(prevYard => {
      const card = deck.find(a => a.id === id)
      return [...prevYard, card]
    })
  }

  let showDeck = deck?.map(a => {
    let last = deck[deck.length - 1] || {id: '0'}
    let isLast = a.id == last.id
    return (
      <div style={{position: 'absolute', pointerEvents: go ? 'auto' : 'none'}} key={a.id}>
        <Card onSwipe={dir => onSwipe(dir, a.id)}>
          {!isLast && (
            <div
              style={{
                marginTop: 30,
                height: 228,
                width: 187,
                borderRadius: 10,
                border: '1px solid #888',
                backgroundColor: '#fbf7f5',
              }}
            />
          )}
          {isLast && (
            <div
              style={{
                marginTop: 30,
                height: 228,
                width: 187,
                borderRadius: 10,
                border: '1px solid #888',
                opacity: go ? 1 : 0,
                transition: 'opacity 900ms ease-in-out',
                backgroundColor: a.backgroundColor,
              }}
            />
          )}
        </Card>
      </div>
    )
  })

  let bot = () => {
    return (
      <div className={'flexCol'} style={{flex: 1, alignItems: 'center'}}>
        {showDeck}
      </div>
    )
  }

  return (
    <div className={'flexCol'} style={{width: 360, flex: 1}}>
      <Top aim={aim} toggle={toggle} board={board} />
      {bot()}
      <Menu
        wid={wid}
        on={on}
        clock={clock}
        room={room}
        join={join}
        make={make}
        lobby={lobby}
        start={start}
        joinId={joinId}
        setJoinId={setJoinId}
      />
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
    marginBottom: 80,
  },
}

export default Tiles

//show
/*
  let show = board?.map((a, k) => {
    let backgroundColor = '#fbf7f5'
    let borderColor = '#aaa'
    let opacity = 1

    return (
      <div onClick={() => toggleBoard(k)} key={k} style={{...styles.box, overflow: 'hidden'}}>
        <div style={{...styles.tile, backgroundColor, opacity, borderColor}}></div>
      </div>
    )
  })
  */
//ripple
/*
  {k == 18 && (
    <div className="circles">
      <div className="circle1" />
      <div className="circle2" />
    </div>
  )}
  */
// arcs
/*
  const removeArc = id => {
    setArcs(arcs => arcs.filter(a => a.id !== id))
  }

  const addArc = (x, y, x2, y2) => {
    setArcs(arcs => [...arcs, {id: nextId, x, y, x2, y2}])
    setNextId(nextId + 1) // Increment the nextId
  }

  let showArcs = arcs?.map(a => {
    return <Arc key={a.id} x={a.x} y={a.y} x2={a.x2} y2={a.y2} onComplete={() => removeArc(a.id)} />
  })

  <div style={{backgroundColor: 'blue', width: 1584, overflow: 'visible'}}>{showArcs}</div>
  */
//shoot
/*
  let shoot = () => {
    let fx = from % 33
    let fy = 18 - Math.floor((from / 594) * 18)
    let tx = to % 33
    let ty = 18 - Math.floor((to / 594) * 18)

    if (from && to) {
      let x = fx * 48 + 24
      let y = fy * -48 + 24
      let x2 = tx * 48 + 24
      let y2 = ty * -48 + 24
      addArc(x, y, x2, y2)
      //
    }
  }
  */
