import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {motion, useAnimation, useTransform, useMotionValue} from 'framer-motion'

let Card = ({onSwipe, children}) => {
  let [show, setShow] = useState(0)
  let anim = useAnimation()
  let x = useMotionValue(0)
  let rotate = useTransform(x, [-200, 200], [-20, 20])
  let opacity = useTransform(x, [-200, -100, 0, 100, 200], [0, 1, 1, 1, 0])

  let onEnd = (_, info) => {
    const offset = info.offset.x
    const vx = info.velocity.x
    //console.log('vx', vx)
    if (Math.abs(offset) < 150) {
      anim.start({x: 0})
    } else {
      onSwipe(offset < 0 ? 'left' : 'right')
      anim.start({x: offset < 0 ? -300 : 300})
    }
  }

  return (
    <motion.div center={'true'} drag="x" style={{x, opacity, rotate}} animate={anim} rotate={rotate} onDragEnd={onEnd}>
      {children}
    </motion.div>
  )
}

//dragElastic={1}

export default Card
